const facebookSDKURL = 'https://connect.facebook.net/fr_CA/sdk.js';
const appID = window.Academos ? window.Academos.FacebookAppID : null;
const graphAPIVersion = 'v16.0';

let fbSDKInitialized = false;

const triggerFBReady = () => {
  fbSDKInitialized = true;
  $(document).trigger('fb:ready');
};

window.fbAsyncInit = function () {
  window.FB.init({
    appId: appID,
    cookie: true,
    xfbml: true,
    version: graphAPIVersion,
  });

  triggerFBReady();

  // Analyser les plugins XFBML
  if (window.FB) {
    window.FB.XFBML.parse();
  }
};

export const isFBReady = () => {
  return fbSDKInitialized && window.FB;
};

const bindFacebookEvents = () => {
  let facebookRoot;
  const $document = $(document);

  $document.on('turbolinks:request-start', () => {
    if ($('#fb-root').length) {
      facebookRoot = $('#fb-root').detach();
    }
  });

  $document.on('turbolinks:load', () => {
    const root = $('#fb-root');
    if (root.length > 0) {
      root.replaceWith(facebookRoot);
    } else if (facebookRoot) {
      $('body').append(facebookRoot);
    } else {
      $('body').append('<div id="fb-root"></div>');
    }

    if (window.FB) {
      window.FB.XFBML.parse();
    }
  });
};

export const setup = () => {
  if (window.FB) {
    triggerFBReady();
    return;
  }

  if (!$('#fb-root').length) {
    $('body').append('<div id="fb-root"></div>');
  }

  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    const js = d.createElement(s);
    js.id = id;
    js.src = facebookSDKURL;
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');

  bindFacebookEvents();
};
