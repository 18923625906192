import Component from '../component';
import {isFBReady} from '../../shims/turbolinks-facebook-sdk';

const authPath = window.Academos
  ? window.Academos.FacebookUserAuthCallbackPath
  : '/';
const loginScope = 'email';

export default class FacebookLogin extends Component {
  constructor(element) {
    super(...arguments);
    this.$element = $(element);
    this.bindEvents();
  }

  static initialize(selector) {
    $(selector).each(function () {
      new FacebookLogin(this);
    });
  }

  bindEvents() {
    this.handleClick = this.handleClick.bind(this);
    this.$element.on('click.FacebookLogin', this.handleClick);
  }

  unbindEvents() {
    this.$element.off('click.FacebookLogin', this.handleClick);
  }

  handleClick(e) {
    e.preventDefault();

    if (!isFBReady()) {
      this.$element.addClass('is-loading');

      $(document).one('fb:ready', () => {
        this.$element.removeClass('is-loading');
        this.loginToFacebook();
      });

      return;
    }

    this.loginToFacebook();
  }

  loginToFacebook() {
    if (!window.FB) {
      return;
    }

    window.FB.login(
      (response) => {
        if (response.authResponse) {
          this.redirectToAuthPath();
        }
      },
      {
        scope: loginScope,
        // eslint-disable-next-line camelcase
        auth_type: 'rerequest',
        // eslint-disable-next-line camelcase
        return_scopes: true,
      }
    );
  }

  redirectToAuthPath() {
    window.location = authPath;
  }
}
